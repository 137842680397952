/* @flow */

import {
  SUBMIT_ESTIMATE,
  ESTIMATE_BOOKING,
  ESTIMATE_BOOKING_SUCCESS,
  ESTIMATE_BOOKING_FAILURE,
  DISPLAY_FORM_VALIDATION_ERRORS,
} from '../actions/estimate';
import type { Estimation } from '../types';
import type { State } from './index';

export type EstimateState = {
  isFetching: boolean,
  estimation?: Estimation,
  hasBeenSubmit: boolean,
  displayFormValidationErrors: boolean,
};

const initialState: EstimateState = {
  isFetching: false,
  hasBeenSubmit: false,
  displayFormValidationErrors: false,
};

export default (state: EstimateState = initialState, action: any) => {
  switch (action.type) {
    case SUBMIT_ESTIMATE:
      return {
        ...state,
        hasBeenSubmit: true,
      };
    case DISPLAY_FORM_VALIDATION_ERRORS:
      return {
        ...state,
        displayFormValidationErrors: action.value,
        estimation: action.value ? undefined : state.estimation,
      };
    case ESTIMATE_BOOKING:
      return {
        ...state,
        isFetching: true,
      };
    case ESTIMATE_BOOKING_SUCCESS:
      return {
        ...state,
        estimation: action.estimation,
        isFetching: false,
      };
    case ESTIMATE_BOOKING_FAILURE:
      return {
        ...state,
        esimation: undefined,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const hasBeenFetched = (state: State) => !!state.estimate.estimation;
export const getIsFetching = (state: State) => state.estimate.isFetching;
export const getDisplayFormValidationErrors = (state: State) => (
  state.estimate.displayFormValidationErrors
);
export const getEstimation = (state: State) => state.estimate.estimation;

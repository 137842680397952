import $ from 'jquery';
import isArray from 'lodash.isarray';
import API from '../common/api';
import i18n from '../../i18n';
import { scrollTo } from '../../plugins/smooth-scroll';


export const simulateUpdate = async($updateModal, submitForm) => {
  const $form = $('#orderUpdateForm');
  const orderId = $form.attr('data-order-id');
  const getInputValue = (name) => $form.find(`input[name="${name}"], select[name="${name}"]`).val();
  const simulationData = { vehicle: {}, address: {} };
  const fieldNames = [
    'startDate',
    'startHour',
    'endDate',
    'endHour',
    'endTripNumber',
    'startTripNumber',
    'lastName',
    'firstName',
    'email',
    'phoneNumber',
    'company',
    'vehicle.kind',
    'vehicle.color',
    'address.firstLine',
    'address.secondLine',
    'address.zipCode',
    'address.city',
    'address.country',
  ];

  fieldNames.forEach((name) => {
    const nameParts = name.split('.');
    if (nameParts.length === 2) {
      simulationData[nameParts[0]][nameParts[1]] = getInputValue(name);
    } else {
      simulationData[name] = getInputValue(name);
    }
  });

  try {
    const result = await API.simulateUpdate(orderId, simulationData);

    if (result && result.changes) {
      let html = '';
      if (result.hasCancellationOption) {
        html += `<p>${i18n.t('order_simulate_modal_has_cancellation_option')}</p>`;
      } else {
        html += `<p>${i18n.t('order_simulate_modal_no_cancellation_option')}</p>`;
      }

      const isDepartureUpdated = (result.changes.startDate && result.changes.startDate !== result.changes.previousStartDate)
        || (result.changes.startHour && result.changes.startHour !== result.changes.previousStartHour);
      const isArrivalUpdated = (result.changes.endDate && result.changes.endDate !== result.changes.previousEndDate)
        || (result.changes.endHour && result.changes.endHour !== result.changes.previousEndHour);
      if (isDepartureUpdated && isArrivalUpdated) {
        html += `<p>${i18n.t('order_simulate_modal_change_date_in_and_out', {
          startDate: result.changes.startDate,
          startHour: result.changes.startHour,
          endDate: result.changes.endDate,
          endHour: result.changes.endHour,
        })}</p>`;
      } else if (isDepartureUpdated) {
        html += `<p>${i18n.t('order_simulate_modal_change_date_in', {
          startDate: result.changes.startDate,
          startHour: result.changes.startHour,
        })}</p>`;
      } else if (isArrivalUpdated) {
        html += `<p>${i18n.t('order_simulate_modal_change_date_out', {
          endDate: result.changes.endDate,
          endHour: result.changes.endHour,
        })}</p>`;
      }
      if (result.balance && result.balance.pendingTotal !== 0) {
        let tradKey = '';
        const tradParams = {
          newTotal: result.balance.newTotal,
          alreadyPaid: result.balance.alreadyPaid,
          endDate: result.changes.endDate,
        };
        if (result.balance.pendingTotal > 0) {
          tradParams.pendingTotal = result.balance.pendingTotal;
          switch (result.balance.pendingPaymentType) {
            case 'paytweak':
              tradKey = 'order_simulate_modal_new_balance_with_paytweak';
              break;
            case 'card':
            default:
              tradKey = 'order_simulate_modal_new_balance_with_payment';
              break;
          }
        } else {
          tradKey = 'order_simulate_modal_new_balance_with_refund';
          tradParams.pendingTotal = -result.balance.pendingTotal;
        }
        html += `<p>${i18n.t(tradKey, tradParams)}</p>`;
      }

      if (isDepartureUpdated || isArrivalUpdated) {
        $updateModal.find('#SimulationContent').html(html);
        $.magnificPopup.open({
          items: {
            src: $updateModal,
          },
        });
      } else {
        submitForm();
      }
    }
  } catch (err) {
    if (err && err.res) {
      err.res.json().then((msg) => {
        if (msg && msg.message) {
          const content = isArray(msg.message) ? msg.message.join('<br>') : msg.message;
          const errorDiv = $('#ErrorContainer');
          errorDiv.html(`<div class='message alert'>${content}</div>`);
          scrollTo(errorDiv);
        }
      });
    }
  }
};

import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button } from '@bluevalet/common-ui';
import InsuranceLastMinuteIcon from '@bluevalet/react-icons/insurance-last-minute';
import { removeOption, toggleOption } from '../actions/cart';
import { getServices } from '../reducers/services';
import { getBusiness, getSummary } from '../reducers/cart';
import type { OrderSummary } from '../types';

type Props = {
  t: (key: string) => string,
  summary?: OrderSummary,
  toggleOption: (service: number, previous: boolean) => void,
  businessId: string,
};

function ModalInsuranceUpselling({ t, summary, toggleOption, businessId }: Props) {
  const shouldDisplayUpselling = useRef(false);
  const [displayUpselling, setDisplayUpselling] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const addOption = () => {
    toggleOption(summary?.cancellationOptionId, false);
    setDisplayUpselling(false);
  };

  const submitForm = () => {
    if (!isSubmitted) {
      const validateForm = document.getElementById('ValidateForm');
      if (validateForm) {
        validateForm.submit();
        setSubmitted(true);
      }
    }
  };

  useEffect(() => {
    if (summary) {
      const { isAnyInsuranceAvailable, hasCancellationOption, cancellationOptionId } = summary;
      shouldDisplayUpselling.current =
        isAnyInsuranceAvailable && !hasCancellationOption && cancellationOptionId && !businessId;
    }
  }, [summary, businessId]);

  useEffect(() => {
    const handleSubmitWithUpsell = (e) => {
      if (shouldDisplayUpselling.current) {
        setDisplayUpselling(true);
        e.preventDefault();
      }
    };
    const submitButton = document.querySelector('#validateBooking');
    if (submitButton) {
      submitButton.addEventListener('click', handleSubmitWithUpsell);
      return () => submitButton.removeEventListener('click', handleSubmitWithUpsell);
    }
    return null;
  }, []);

  return displayUpselling && summary && summary.isAnyInsuranceAvailable ? (
    <div className="grey-container-modal">
      <div className="upsellingModal">
        <div className="upsellingModalTitle">
          <InsuranceLastMinuteIcon size={30} />
          <p>{t('booking-modal_upselling_insurance_title')}</p>
        </div>
        <p className="upsellingModalDescription">
          {t('booking-modal_upselling_insurance_description')}
        </p>
        <div className="upsellingModalCtaContainer">
          <Button
            onClick={addOption}
            primary
            label={t('booking-modal_upselling_insurance_cta_accepted')}
          />
          <Button
            onClick={submitForm}
            secondary
            label={t('booking-modal_upselling_insurance_cta_denied')}
          />
        </div>
      </div>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  services: getServices(state),
  summary: getSummary(state),
  businessId: getBusiness(state),
});

export default compose(
  connect(mapStateToProps, {
    toggleOption,
    removeOption,
  }),
  withTranslation(),
)(ModalInsuranceUpselling);

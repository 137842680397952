import $ from 'jquery';
import 'magnific-popup';

export default () => {
  const $modal = $('#SponsorshipModal');
  const $scrollableMenu = $('#ScrollableMenu');

  if ($modal && $modal.length) {
    $modal.find('.js-close-popup').on('click', () => $.magnificPopup.close());

    $.magnificPopup.open({
      items: {
        src: $modal,
      },
      callbacks: {
        // Fix popup moving scrollableMenu on opening
        open: () => {
          $scrollableMenu.css('top', '0px');
        },
        close: () => {
          $scrollableMenu.css('top', '');
        },
      },
    });
  }
};

export type ConfigMode = 'estimate' | 'cart';

export type Checkpoint = {
  id: number,
  name: string,
};

export type Site = {
  id: number,
  name: string,
  maxHeight?: number,
  checkpoints: Array<Checkpoint>,
  type: string,
};

export type Slots = {
  step: number,
  startTime: string,
  endTime: string,
  unavailables: Array<number>,
};

export type SiteSwitch = {
  from: number,
  to: number,
  serviceSiteSite: number,
};

export type Estimation = {
  total: number,
  durationInDays: number,
  localPrice?: number,
  switchPrice?: number,
  priceSaved?: number,
  timeSaved: number,
  priceWithoutPromotion: number
};

export type Service = {
  id: number,
  label: string,
  description: string,
  disabledForShortBooking: boolean,
  disabledForWeekEnd: boolean,
  enabledByDefault: boolean,
  price: number,
  isDisabled: boolean,
  isExclusiveWith?: number,
  isInsurance?: boolean
};

export type OrderService = {
  id: number,
  price: number,
  label: string,
};

export type VoucherStatus =
  | 'NotFound'
  | 'AlreadyUsed'
  | 'RequireAuthentication'
  | 'Valid';

export type OrderVoucher = {
  code: string,
  status: VoucherStatus,
  discount?: number,
};

export type OrderSummary = {
  base: number,
  total: number,
  durationInDays: number,
  localPrice?: number,
  priceSaved?: number,
  timeSaved: number,
  services: Array<OrderService>,
  siteSwitch?: number,
  voucher?: OrderVoucher,
  hasBasicOption: Boolean,
  hasCancellationOption: Boolean,
  isCancellationOptionFree: Boolean,
  isAnyInsuranceAvailable: Boolean,
  totalBasic: number,
  totalWithCancellation: number,
  valetDiscount?: number,
  isDiscountSitePromotion: Boolean,
  baseWithoutDiscount: number,
  cancellationOptionId: number,
};

export type BusinessCompany = {
  id: string,
  name: string,
};

export type BusinessWorker = {
  id: string,
  business: string,
  worker: string,
};

export type TripDetail = {
  number: string,
  departureTime: string,
  arrivalTime: string,
};


export const SiteType = {
  Airport: 'airport',
  Station: 'station',
};

export const OptionTypes = {
  basic: 'basic',
  premium: 'premium',
};

export const TravelTypes = {
  perso: 'personal',
  pro: 'professional',
  bvb: 'bluevaletbusiness',
};

/* @flow */

import moment from 'moment';
import $ from 'jquery';
import URI from 'urijs';
import fetchUrl, { fetchJson, postJson } from '../../utils/fetch';
import { getLangFromCookies } from '../../utils/cookies';

const displayDate = (date: Date) => moment(date).format('DD/MM/YYYY');

const matchTripNumber = [{
  start: 'EJU',
  replace: 'EZY',
}, {
  start: 'EZS',
  replace: 'EZY',
}, {
  start: 'CE',
  replace: 'CE0',
}, {
  start: 'EK',
  replace: 'EK0',
}, {
  start: 'I2',
  replace: 'IB',
}];

export default class API {
  static getSites() {
    const lang = getLangFromCookies();

    return fetchJson(`/sites.json?lang=${lang}`);
  }

  static getSiteDepartureSlots(site: string, date: Date, orderId?: string) {
    const orderIdPart = orderId ? `&order_id=${orderId}` : '';
    return fetchJson(
      `/sites/slots/departure.json?site=${site}&date=${displayDate(date)}${orderIdPart}`,
    );
  }

  static getSiteArrivalSlots(site: string, date: Date, orderId?: string) {
    const orderIdPart = orderId ? `&order_id=${orderId}` : '';
    return fetchJson(
      `/sites/slots/arrival.json?site=${site}&date=${displayDate(date)}${orderIdPart}`,
    );
  }

  static getServices(startSite, endSite, startDate, endDate, business, worker) {
    const datePart = startDate && endDate
      ? `&start_date=${startDate}&end_date=${endDate}`
      : '';

    const businessPart = business ? `&business_id=${business}` : '';
    const workerPart = worker ? `&worker_id=${worker.id}` : '';

    return fetchJson(
      `/services.json?start_site=${startSite}&end_site=${endSite}${datePart}${businessPart}${workerPart}`,
    );
  }

  static getCompanies() {
    return fetchUrl('/business/companies.json', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  }

  static getSummary() {
    return fetchUrl('/cart/summary.json', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  }

  static estimate(start, end) {
    return postJson('/orders/estimate', {
      ...start,
      ...end,
    });
  }

  static getValidateForm(business) {
    const displayErrors = $('#CurrentCart').hasClass('display-error');
    const url = new URI(`/cart/validate.html?display_errors=${displayErrors}`);

    if (business) {
      url.addQuery('worker', business);
    }

    const data = new FormData($('#ValidateForm')[0]);

    return fetchUrl(url.toString(), {
      method: 'POST',
      body: data,
      headers: {
        Cache: 'no-cache',
      },
    }).then((res) => {
      if (res.status === 200) {
        return res.text();
      }
      return null;
    });
  }

  static saveCart(body) {
    return fetchUrl('/cart', {
      method: 'PUT',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static getQuery(prefix, site, date, query) {
    const url = new URI(`/transport/${prefix}.json`);
    // Ajout du site
    url.addQuery('site', site);

    // Ajout de la date
    url.addQuery('date', date);

    // Ajout du numéro de vol / train
    let tempQuery = query;
    if (tempQuery) {
      tempQuery = tempQuery.toUpperCase();
      const findReplace = matchTripNumber.find(match => tempQuery.startsWith(match.start));
      if (findReplace && !tempQuery.match(findReplace.replace)) {
        tempQuery = tempQuery.replace(findReplace.start, findReplace.replace);
      }
    }

    url.addQuery('query', tempQuery);

    return url;
  }

  static getTripInfos(prefix, site, date, query, signal) {
    const url = this.getQuery(prefix, site, date, query);

    return fetchJson(url, {
      method: 'GET',
      signal,
    });
  }

  static simulateUpdate(orderId, form) {
    return postJson(`/orders/${orderId}/simulate`, form);
  }
}

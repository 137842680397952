import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';
import { Button } from '@bluevalet/common-ui';
import BriefcaseIcon from '@bluevalet/react-icons/briefcase';

import { getBusiness, getTravelType, getWorker } from '../reducers/cart';
import { getCompanies } from '../reducers/business';

import type { BusinessCompany, BusinessWorker } from '../types';
import { TravelTypes } from '../types';

type Props = {
  t: (key: string) => string,
  travelType?: string,
  business?: string,
  worker?: BusinessWorker,
  companies: Array<BusinessCompany>,
};

function ModalBusinessConfirm({ t, travelType, business, worker, companies }: Props) {
  const shouldDisplayModal = useRef(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const submitForm = () => {
    if (!isSubmitted) {
      const validateForm = document.getElementById('ValidateForm');
      if (validateForm) {
        validateForm.submit();
        setSubmitted(true);
      }
    }
  };

  const getCurrentCompany = (business, companies) => {
    const cie = companies?.find((c) => c.id.toString() === business.toString());
    return cie ? cie.name : '';
  };

  useEffect(() => {
    shouldDisplayModal.current = travelType === TravelTypes.bvb && !worker;
  }, [travelType, worker]);

  useEffect(() => {
    const handleSubmit = (e) => {
      if (shouldDisplayModal.current) {
        e.preventDefault();
        e.stopImmediatePropagation();
        setDisplayModal(true);
      }
    };
    const submitButton = document.querySelector('#validateBooking');
    if (submitButton) {
      submitButton.addEventListener('click', handleSubmit);
      return () => submitButton.removeEventListener('click', handleSubmit);
    }
    return null;
  }, []);

  return (
    displayModal && (
      <div className="grey-container-modal">
        <div id="ConfirmModal" className="upsellingModal">
          <div className="upsellingModalTitle">
            <BriefcaseIcon size={30} />
            <p>{t('booking-modal_bvb_confirm_title')}</p>
          </div>
          <p className="upsellingModalDescription">
            <Trans
              i18nKey="booking-modal_bvb_confirm_content"
              values={{ label: getCurrentCompany(business, companies) }}
            />
          </p>
          <div className="upsellingModalCtaContainer">
            <Button
              onClick={() => setDisplayModal(false)}
              secondary
              label={t('booking-modal_bvb_confirm_cancel')}
              data-cy="cancel-button"
            />
            <Button
              onClick={() => submitForm()}
              primary
              label={t('booking-modal_bvb_confirm_ok')}
              data-cy="confirm-button"
            />
          </div>
        </div>
      </div>
    )
  );
}

const mapStateToProps = (state) => ({
  travelType: getTravelType(state),
  worker: getWorker(state),
  business: getBusiness(state),
  companies: getCompanies(state),
});

export default compose(connect(mapStateToProps), withTranslation())(ModalBusinessConfirm);

import ReactDOM from 'react-dom';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import LandingSiteInfo from '../booking/components/LandingSiteInfo';

export default function() {
  const root = document.getElementById('LandingSiteInfoRoot');

  if (root) {
    const siteCode = root.getAttribute('data-site-code');
    ReactDOM.render(
      <React.Suspense fallback={<div className="fallback" />}>
        <I18nextProvider i18n={i18n}>
          <LandingSiteInfo siteCode={siteCode} />
        </I18nextProvider>
      </React.Suspense>,
      root,
    );
  }
}

// @flow

/*
  autoMergeLevel3:
    - merges 2 level of substate
    - skips substate if already modified
    - this is essentially redux-perist v4 behavior
*/

import type { PersistConfig } from 'redux-persist/lib/types';

function isPlainEnoughObject(o) {
  return o !== null && !Array.isArray(o) && typeof o === 'object';
}

export default function autoMergeLevel3<State: Object>(
  inboundState: State,
  originalState: State,
  reducedState: State,
  { debug }: PersistConfig,
): State {
  const newState = { ...reducedState };
  // only rehydrate if inboundState exists and is an object
  if (inboundState && typeof inboundState === 'object') {
    Object.keys(inboundState).forEach((key) => {
      // ignore _persist data
      if (key === '_persist') return;

      // if reducer modifies substate, skip auto rehydration
      if (originalState[key] !== reducedState[key]) {
        if (process.env.NODE_ENV !== 'production' && debug) {
          console.log('redux-persist/stateReconciler: sub state for key `%s` modified, skipping.', key);
          return;
        }
      }
      if (isPlainEnoughObject(reducedState[key])) {
        // Bugfix Navigation BVB => get current cart from originalState
        if (key === 'cart') {
          newState[key] = { ...newState[key], ...originalState[key] };
          return;
        }
        newState[key] = { ...newState[key], ...inboundState[key] };
        return;
      }
      // otherwise hard set
      newState[key] = inboundState[key];
    });
  }

  if (process.env.NODE_ENV !== 'production' && debug && inboundState && typeof inboundState === 'object') {
    console.log(`redux-persist/stateReconciler: rehydrated keys '${Object.keys(inboundState).join(', ')}'`);
  }

  return newState;
}

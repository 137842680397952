/* @flow */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { withOrder } from '../withOrder';
import AvailableOptions from '../AvailableOptions';
import { getError, getSummary } from '../../reducers/cart';
import { getServices } from '../../reducers/services';
import { toggleOption, removeOption } from '../../actions/cart';
import { stringNotEmpty } from '../../utils';

import type { OrderSummary, Service } from '../../types';
import type { FormContent } from '../Form';

type Props = {
  t: (key: string) => string,
  summary?: OrderSummary,
  toggleOption: (service: number, previous: boolean) => void,
  removeOption: (service: number) => void,
  services: Array<Service>,
  departure: FormContent,
  arrival: FormContent,
  error?: string,
};

function OptionsStep({
  t,
  summary,
  toggleOption,
  removeOption,
  services,
  departure,
  arrival,
  error,
}: Props) {
  const [options, setOptions] = useState(services.filter((service) => service.isInsurance));

  useEffect(() => {
    setOptions(services.filter((service) => service.isInsurance));
  }, [services]);

  const isTripFulfilled = () => stringNotEmpty(departure.site)
    && stringNotEmpty(departure.checkpoint)
    && stringNotEmpty(departure.date)
    && stringNotEmpty(departure.hour)
    && stringNotEmpty(arrival.site)
    && stringNotEmpty(arrival.checkpoint)
    && stringNotEmpty(arrival.date)
    && stringNotEmpty(arrival.hour);

  return (
    <div id="order-options" className="orderOptions bookingStep">
      {!error && (
        <>
          <h2>3. {t('booking-options_title')}</h2>
          <div className="booking-content">
            {
              summary && summary.isAnyInsuranceAvailable ?
                (
                  <AvailableOptions
                    summary={summary}
                    addOption={toggleOption}
                    removeOption={removeOption}
                    options={options}
                  />
                ) : (
                  <div className="no-services">
                    {
                      isTripFulfilled() ?
                        t('booking-options_no-options-available') :
                        t('booking-options_fill-details-before')
                    }
                  </div>
                )
            }
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  services: getServices(state),
  summary: getSummary(state),
  error: getError(state),
});

export default compose(
  withOrder,
  connect(mapStateToProps, {
    toggleOption,
    removeOption,
  }),
  withTranslation(),
)(OptionsStep);

/* @flow */

import { FETCH_SERVICES, FETCH_SERVICES_SUCCESS } from '../actions/service';
import type { Service } from '../types';
import type { State } from './index';

export type ServicesState = {
  isFetching: boolean,
  services: Array<Service>,
};

const initialState = {
  isFetching: false,
  services: [],
};

export default (state: ServicesState = initialState, action: any) => {
  switch (action.type) {
    case FETCH_SERVICES:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        services: action.services,
      };
    default:
      return state;
  }
};

export const getServices = (state: State) => state.services.services;

export const getServiceById = (state: State, id: number) => {
  const services = getServices(state);
  return services.find((s) => s.id.toString() === id.toString());
};

/* @flow */

import { getBooking } from '../reducers/booking';
import { getSites } from '../reducers/sites';
import { ESTIMATE_BOOKING_SUCCESS } from '../actions/estimate';
import { pushEstimationEvents } from '../tagging';

export default store => next => (action) => {
  next(action);

  if (action.type === ESTIMATE_BOOKING_SUCCESS) {
    const state = store.getState();

    const sites = getSites(state);
    const booking = getBooking(state);

    pushEstimationEvents(sites, action.estimation, booking);
  }
};

/* @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import moment from 'moment';
import isEmpty from 'lodash.isempty';

import { withSites } from '../withSites';
import { withOrder } from '../withOrder';
import Departure from '../DepartureForm';
import Arrival from '../ArrivalForm';
import BookingError from '../BookingError';
import { getSummary, getError } from '../../reducers/cart';
import { persistCart } from '../../actions/cart';
import { pushCheckoutEvents } from '../../tagging';

import type { Site, OrderSummary, Slots } from '../../types';
import type { FormContent, TripActions } from '../Form';

type Props = {
  t: (key: string) => string,
  sites: Array<Site>,
  arrivalSites: Array<Site>,
  departure: FormContent,
  departureSlots?: Slots,
  arrival: FormContent,
  arrivalSlots?: Slots,
  forceValidation: boolean,
  summary?: OrderSummary,
  error?: string,
  onDepartureChanged: (departure: FormContent) => void,
  onArrivalChanged: (arrival: FormContent) => void,
  persistCart: () => void,
  isFetchingDepartureSlots: boolean,
  isFetchingArrivalSlots: boolean,
  trip: () => Object,
  tripActions: TripActions,
};

class BookingStep extends PureComponent {
  static props: Props;

  componentDidMount() {
    const {
      departure,
      arrival,
      persistCart,
      tripActions: { fetchTripInfos, selectTripInfos, forceTripInfos, toggleUnknownTripInfos },
    } = this.props;

    persistCart();

    fetchTripInfos(
      'start',
      departure.site,
      moment(departure.date).format('DD/MM/YYYY'),
      departure.tripNumber,
    );
    fetchTripInfos(
      'end',
      arrival.site,
      moment(arrival.date).format('DD/MM/YYYY'),
      arrival.tripNumber,
    );

    if (this.isTripValid(departure)) {
      selectTripInfos('start');
    }

    if (this.isTripForced(departure)) {
      forceTripInfos('start');
    }

    if (this.isTripUnknown(departure)) {
      toggleUnknownTripInfos('start');
    }

    if (this.isTripValid(arrival)) {
      selectTripInfos('end');
    }

    if (this.isTripForced(arrival)) {
      forceTripInfos('end');
    }

    if (this.isTripUnknown(arrival)) {
      toggleUnknownTripInfos('end');
    }

    const form = document.querySelector('#ValidateForm');
    const handleSubmit = () => {
      const { summary } = this.props;
      pushCheckoutEvents(summary);
    };
    if (form) {
      form.addEventListener('submit', handleSubmit);
    }
  }

  componentDidUpdate(prevProps) {
    const { departure, arrival, persistCart } = this.props;
    if (prevProps.departure !== departure || prevProps.arrival !== arrival) {
      persistCart();
    }
  }

  isTripValid = (travel) => {
    const { t } = this.props;
    const unknownTripNumber = t('booking-trip_unknown_number_sel');
    return (
      !!travel.tripNumber && travel.tripNumber !== unknownTripNumber && !isEmpty(travel.tripDetail)
    );
  };

  isTripForced = (travel) => {
    const { t } = this.props;
    const unknownTripNumber = t('booking-trip_unknown_number_sel');
    return (
      !!travel.tripNumber && travel.tripNumber !== unknownTripNumber && isEmpty(travel.tripDetail)
    );
  };

  isTripUnknown = (travel) => {
    const { t } = this.props;
    const unknownTripNumber = t('booking-trip_unknown_number_sel');
    return (
      !!travel.tripNumber && travel.tripNumber === unknownTripNumber && isEmpty(travel.tripDetail)
    );
  };

  render() {
    const {
      t,
      sites,
      arrivalSites,
      departure,
      departureSlots,
      arrival,
      arrivalSlots,
      error,
      forceValidation,
      isFetchingDepartureSlots,
      isFetchingArrivalSlots,
      trip,
      tripActions,
      onDepartureChanged,
      onArrivalChanged,
    } = this.props;

    return (
      <div id="order-detail" className="bookingStep">
        <h2>{`1. ${t('booking-your_order')}`}</h2>

        <div className="booking-content">
          <BookingError error={error} />

          <div className="travel">
            <div className="departure">
              <div className="title-form">
                <h3>{t('booking-departure')}</h3>
              </div>

              <Departure
                content={departure}
                sites={sites}
                slots={departureSlots}
                forceValidation={forceValidation}
                onChange={(departure) => onDepartureChanged(departure, false)}
                isFetchingSlots={isFetchingDepartureSlots}
                trip={trip.start}
                actions={tripActions}
              />
            </div>

            <div className="arrival">
              <div className="title-form">
                <h3>{t('booking-arrival')}</h3>
              </div>

              <Arrival
                displaySite
                content={arrival}
                sites={arrivalSites}
                slots={arrivalSlots}
                forceValidation={forceValidation}
                onChange={onArrivalChanged}
                isFetchingSlots={isFetchingArrivalSlots}
                trip={trip.end}
                actions={tripActions}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  summary: getSummary(state),
  error: getError(state),
});

export default compose(
  withOrder,
  withSites,
  connect(mapStateToProps, {
    persistCart,
  }),
  withTranslation(),
)(BookingStep);

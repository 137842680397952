/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { getBusiness, getTravelType, getWorker } from '../reducers/cart';
import { getCompanies } from '../reducers/business';
import { persistCart, updateTravelType } from '../actions/cart';
import { withOrder } from './withOrder';
import Worker from './Worker';
import { TravelTypes } from '../types';

import type { BusinessCompany, BusinessWorker } from '../types';

type Props = {
  t: (key: string) => string,
  travelType?: string,
  business?: string,
  worker?: BusinessWorker,
  companies: Array<BusinessCompany>,
  updateTravelType: (travelType?: string, business?: string) => void,
  persistCart: () => void,
};

function TravelType({
  t,
  travelType,
  business,
  worker,
  companies,
  updateTravelType,
  persistCart,
}: Props) {
  const isTravelTypeChecked = (type) => type === travelType;
  const isCompanyActive = (company: BusinessCompany) => company.id.toString() === business;

  const onTravelTypeChange = (type, business) => {
    updateTravelType(type, business);
    persistCart();
  };

  if (worker) {
    return <Worker t={t} worker={worker} />;
  }

  return (
    <div className="booking-trip-kind">
      <fieldset>
        <legend>{t('booking-trip_type')}</legend>
        <div className="form">
          <div className="tripsType">
            <div className="radio">
              <label htmlFor="kind_perso">
                <input
                  type="radio"
                  id="kind_perso"
                  name="travelType"
                  value={TravelTypes.perso}
                  onChange={() => onTravelTypeChange(TravelTypes.perso)}
                  checked={isTravelTypeChecked(TravelTypes.perso)}
                />
                <span className="checkmark" />
                {t('booking-kind-personal')}
              </label>
            </div>

            {(!companies || companies.length === 0) && (
              <div className="radio">
                <label htmlFor="kind_pro">
                  <input
                    type="radio"
                    id="kind_pro"
                    name="travelType"
                    value={TravelTypes.pro}
                    onChange={() => onTravelTypeChange(TravelTypes.pro)}
                    checked={isTravelTypeChecked(TravelTypes.pro)}
                  />
                  <span className="checkmark" />
                  {t('booking-kind-professional')}
                </label>
              </div>
            )}

            {companies && companies.length > 0 && (
              companies.map((company, i) => (
                <div className="radio" key={company.id}>
                  <label htmlFor={`kind_bvb_${i}`}>
                    <input
                      type="radio"
                      id={`kind_bvb_${i}`}
                      name="business"
                      value={`${TravelTypes.bvb}_${company.id}`}
                      onChange={() => onTravelTypeChange(TravelTypes.bvb, company.id.toString())
                    }
                      checked={isCompanyActive(company)}
                    />
                    <span className="checkmark" />
                    <Trans i18nKey="booking-kind-business" values={{ label: company.name }} />
                  </label>
                </div>
              ))
            )}
          </div>
        </div>
      </fieldset>
    </div>
  );
}

const mapStateToProps = (state) => ({
  travelType: getTravelType(state),
  worker: getWorker(state),
  business: getBusiness(state),
  companies: getCompanies(state),
});

export default compose(
  withOrder,
  connect(mapStateToProps, {
    persistCart,
    updateTravelType,
  }),
  withTranslation(),
)(TravelType);

/* @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import * as Immutable from 'immutable';

import { withOrder } from '../withOrder';
import AvailableServices from '../AvailableServices';
import { getServices } from '../../reducers/services';
import { getOptions, getBusiness, getSummary, getError } from '../../reducers/cart';
import { fetchServices } from '../../actions/service';
import { toggleOption, persistCart } from '../../actions/cart';

import type { Service, OrderSummary } from '../../types';
import type { FormContent } from '../Form';


type Props = {
  t: (key: string) => string,
  departure: FormContent,
  arrival: FormContent,
  services: Array<Service>,
  options?: Immutable.Set<number>,
  business?: string,
  summary?: OrderSummary,
  error?: string,
  fetchServices: () => void,
  toggleOption: (option: number) => void,
  persistCart: () => void,
};

class ServicesStep extends PureComponent {
  static props: Props;

  componentDidUpdate(prevProps) {
    const {
      departure,
      arrival,
      options,
      business,
      persistCart,
      fetchServices,
    } = this.props;
    if (prevProps.options !== options) {
      persistCart();
    }
    if (prevProps.departure !== departure
      || prevProps.arrival !== arrival
      || prevProps.business !== business
    ) {
      fetchServices();
    }
  }

  render() {
    const {
      t,
      services,
      departure,
      arrival,
      options,
      error,
      summary,
      toggleOption,
    } = this.props;

    const servicesWithoutOptions = services.filter((service) => !service.isInsurance);

    return (
      <div id="order-services" className="bookingStep">
        {!error && (
          <>
            <h2>{`2. ${t('booking-services')}`}</h2>
            <div className="booking-content">
              <AvailableServices
                departure={departure}
                arrival={arrival}
                services={servicesWithoutOptions}
                enabled={options}
                summary={summary}
                onToggleService={toggleOption}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  services: getServices(state),
  options: getOptions(state),
  business: getBusiness(state),
  summary: getSummary(state),
  error: getError(state),
});

export default compose(
  withOrder,
  connect(
    mapStateToProps,
    {
      fetchServices,
      toggleOption,
      persistCart,
    },
  ),
  withTranslation(),
)(ServicesStep);

import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { I18nextProvider, withTranslation } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { compose } from 'recompose';
import 'magnific-popup';
import IconChevron from '@bluevalet/react-icons/icon-chevron';

import i18n from '../../i18n';
import bindPhoneNumbers from '../../plugins/phone-number';
import formValidation from '../../plugins/form';
import API from '../common/api';
import ScrollMenu from './components/ScrollMenu';
import FullBookingSummary from './components/FullBookingSummary';
import SiteInfo from './components/SiteInfo';
import BookingStep from './components/steps/BookingStep';
import ServicesStep from './components/steps/ServicesStep';
import OptionsStep from './components/steps/OptionsStep';
import ModalBusinessConfirm from './components/ModalConfirm';
import ModalInsuranceUpselling from './components/ModalInsuranceUpselling';
import TravelType from './components/TravelType';
import getStore from './store';
import { appendClientIdToForm } from '../tagging';
import { handleBookingAction, pushSignInCartEvent, pushSignUpCartEvent, pushValidateSignUpCartEvent } from './tagging';
import { CART_PERSIST_ERROR, CART_PERSISTED, FETCH_SUMMARY_FAILURE, fetchSummary } from './actions/cart';
import { fetchSlotsOnBoot } from './actions/slot';
import { fetchServices } from './actions/service';
import { fetchCompanies } from './actions/business';
import { fetchSites } from './actions/site';
import isInViewport from '../../utils/viewport';


const getValidateForm = () => $('#ValidateForm');
const getOrderInformations = () => $('#order-informations');

function onCartPersistError() {
  // Hide validation form on error
  getValidateForm()
    .find('#order-informations')
    .hide();
  getValidateForm()
    .find('#order-total')
    .hide();
}

function bindSignIn() {
  const $signIn = $('#LoginForm');
  const $signUp = $('#SignupForm');
  const $signInTab = $('a.sign-in-tab');
  const $signUpTab = $('a.sign-up-tab');

  const toggle = (event) => {
    event.preventDefault();

    if ($signIn.hasClass('active')) {
      $signIn.removeClass('active');
      $signUp.addClass('active');
      $signInTab.removeClass('active');
      $signUpTab.addClass('active');
    } else {
      $signIn.addClass('active');
      $signUp.removeClass('active');
      $signInTab.addClass('active');
      $signUpTab.removeClass('active');
    }

    pushSignUpCartEvent();
  };

  $('a.booking-content-tab').on('click', toggle);
  $signIn.find('.link a').on('click', toggle);
  $signUp.find('.link a').on('click', toggle);

  $('#CartSignInForm').on('submit', () => {
    pushSignInCartEvent();
  });

  $('#CartSignUpForm').on('submit', () => {
    pushValidateSignUpCartEvent();
  });
}

function bindTagging() {
  appendClientIdToForm(getValidateForm());
}

function bindConfirm() {
  const $form = getValidateForm();

  // Prevent the user from validating an order while the form is already being submitted
  $form.on('submit', () => {
    $('button').prop('disabled', true);
  });

  // Fix browsing back after submit success
  $(window).on('unload', () => {
    $('button').prop('disabled', false);
  });
}

function renderSiteInfo(persistedStore) {
  const rootSiteInfo = document.getElementById('SiteInfoRoot');
  if (rootSiteInfo) {
    const mapStateToProps = (state) => ({
      ...state.sites,
      ...state.booking,
    });
    const SiteInfoComponent = compose(
      connect(mapStateToProps),
      withTranslation(),
    )(SiteInfo);

    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <SiteInfoComponent />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootSiteInfo,
    );
  }
}

function renderTravelType(persistedStore) {
  const rootTravelType = document.getElementById('TravelTypeRoot');
  if (rootTravelType) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <TravelType />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootTravelType,
    );
  }
}

function onCartPersisted(store) {
  const { business } = store.getState().cart;

  // Replacing validate form content
  API.getValidateForm(business)
    .then((text) => {
      if (text) {
        const $htmlFromServer = $(text);
        getValidateForm().show();
        // Replacing forms with HTML content received from server
        const orderInformations = $htmlFromServer.find('#order-informations');
        getOrderInformations()
          .replaceWith(orderInformations)
          .show();

        const persistedStore = getStore();
        renderSiteInfo(persistedStore);
        renderTravelType(persistedStore);

        // Enabling UI behaviors
        bindPhoneNumbers();
        formValidation();

        getValidateForm()
          .find('#order-total')
          .show();
      }
    })
    .catch(() => {
      /* Nothing to do  */
    });
}

function onRehydrate(store) {
  store.dispatch(fetchSlotsOnBoot());
  store.dispatch(fetchSites());
  store.dispatch(fetchServices());
  store.dispatch(fetchCompanies());
  store.dispatch(fetchSummary());
}

const bookingMiddleware = (store) => (next) => (action) => {
  next(action);

  // Handling async errors
  switch (action.type) {
    case REHYDRATE:
      onRehydrate(store);
      break;
    case CART_PERSISTED:
      onCartPersisted(store);
      break;
    case CART_PERSIST_ERROR:
    case FETCH_SUMMARY_FAILURE:
      onCartPersistError();
      break;
    default:
      break;
  }

  // Perform tagging based on action
  handleBookingAction(store, action);
};

function renderScrollMenu() {
  const root = document.getElementById('ScrollableMenu');
  if (root) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <div className="ScrollableWrapper">
            <div className="ScrollMenuBack">
              <IconChevron size={38} onClick={() => window.history.back()} role="button" />
            </div>
            <ScrollMenu />
          </div>
        </I18nextProvider>
      </React.Suspense>,
      root,
    );
  }
}

function clearLocalStorageIfNeeded() {
  const overrideLocalStorageInput = $('input[name="overrideLocalStorage"]');

  const overrideLocalStorage = overrideLocalStorageInput ? overrideLocalStorageInput.val() === 'true' : false;

  if (overrideLocalStorage) {
    localStorage.removeItem('persist:root');
  }
}

function BookingLoader({ title, height }) {
  return (
    <div className="booking bookingStep">
      <h2>{title}</h2>
      <div className="booking-content" style={{ minHeight: `${height}px`, justifyContent: 'center' }}>
        <div className="bv-spinner">
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );
}

function renderFull(persistedStore) {
  const rootStep1 = document.getElementById('BookingStepRoot');
  const rootStep2 = document.getElementById('ServicesStepRoot');
  const rootStep3 = document.getElementById('OptionsStepRoot');
  const $inputs = $('#CurrentCart');
  const forceValidation = $inputs.hasClass('display-error');

  if (rootStep1) {
    const title = rootStep1.getElementsByTagName('h2').item(0).innerText;
    ReactDOM.render(
      <React.Suspense fallback={<BookingLoader title={title} height={483} />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <BookingStep forceValidation={forceValidation} />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootStep1,
    );
  }

  if (rootStep2) {
    const title = rootStep2.getElementsByTagName('h2').item(0).innerText;
    ReactDOM.render(
      <React.Suspense fallback={<BookingLoader title={title} height={361} />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <ServicesStep />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootStep2,
    );
  }

  if (rootStep3) {
    const title = rootStep3.getElementsByTagName('h2').item(0).innerText;
    ReactDOM.render(
      <React.Suspense fallback={<BookingLoader title={title} height={509} />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <OptionsStep />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootStep3,
    );
  }

  $('#Loader').removeClass('active');
}

function renderSummary(persistedStore, isPayment, isSticky) {
  const rootSummary = document.getElementById('order-summary');
  if (rootSummary) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <FullBookingSummary
                enableVoucher={isPayment}
                enableServiceToggle={!isPayment}
                isSticky={isSticky}
              />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootSummary,
    );
  }
}

function renderUpselling(persistedStore) {
  const rootUpselling = document.getElementById('upselling');
  if (rootUpselling) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <ModalInsuranceUpselling />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootUpselling,
    );
  }
}

function renderConfirmModal(persistedStore) {
  const rootUpselling = document.getElementById('ConfirmModalRoot');
  if (rootUpselling) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <Provider store={persistedStore.store}>
          <PersistGate loading={null} persistor={persistedStore.persistor}>
            <I18nextProvider i18n={i18n}>
              <ModalBusinessConfirm />
            </I18nextProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>,
      rootUpselling,
    );
  }
}

export function Booking() {
  // Clear local storage if requested by server
  clearLocalStorageIfNeeded();

  const persistedStore = getStore([bookingMiddleware]);

  // React rendering
  renderScrollMenu();
  renderFull(persistedStore);
  renderSiteInfo(persistedStore);
  renderTravelType(persistedStore);
  renderConfirmModal(persistedStore);
  renderUpselling(persistedStore);

  // UI behaviors
  bindSignIn();
  bindTagging();
  bindConfirm();

  setTimeout(() => {
    const $success = document.querySelector('#cart-inform-messages-container');
    if ($success && !isInViewport($success)) {
      $success.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, 300);
}

export function Summary(isPayment: boolean, isSticky: boolean) {
  const persistedStore = getStore();
  renderSummary(persistedStore, isPayment, isSticky);
}

/* @flow */

import React from 'react';
import type { BusinessWorker } from '../types';

type Props = {
  t: (key: string) => string,
  worker: BusinessWorker,
};

const Worker = ({ t, worker }: Props) => (
  <div className="booking-trip-kind">
    <fieldset>
      <legend>{t('booking-worker')}</legend>
      <div className="b2b-order-collab">
        <p>
          {t('booking-you_will_order_for_worker')}
          {' '}
          <b>{worker.name}</b>
          {' '}
          ({worker.business})
        </p>
      </div>
    </fieldset>
  </div>
);

export default Worker;

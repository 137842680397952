import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as Immutable from 'immutable';
// import { logger } from 'redux-logger';

import { createTransform, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel3 from './autoMergeLevel3';

import rootReducer from './reducers/index';
import type { ConfigMode } from './types';

const transformBooking = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => ({ ...inboundState }),
  // transform state being rehydrated
  (outboundState) => {
    const departureDate = outboundState.departure.date && new Date(outboundState.departure.date);
    const arrivalDate = outboundState.arrival.date && new Date(outboundState.arrival.date);
    return {
      ...outboundState,
      departure: {
        ...outboundState.departure,
        date: departureDate,
      },
      arrival: {
        ...outboundState.arrival,
        date: arrivalDate,
      },
    };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['booking'] },
);

const transformCart = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => ({
    ...inboundState,
    options: inboundState.options && inboundState.options.toArray(),
  }),
  // transform state being rehydrated
  (outboundState) => ({
    ...outboundState,
    options: Immutable.Set(outboundState.options),
  }),
  // define which reducers this transform gets called for.
  { whitelist: ['cart'] },
);

// Create persisted store
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel3,
  whitelist: ['booking', 'cart'],
  transforms: [transformBooking, transformCart],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default (mode: ConfigMode, initialState = undefined, customMiddlewares = []) => {
  const middlewares = [thunk, ...customMiddlewares];

  const enhancers = [applyMiddleware(...middlewares)];

  const configuredState = {
    ...initialState,
    config: {
      mode,
    },
  };

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(persistedReducer, configuredState, composeEnhancers(...enhancers));

  const persistor = persistStore(store);

  return { store, persistor };
};

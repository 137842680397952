/* @flow */

import React from 'react';
import { withTranslation } from 'react-i18next';

import AvailableOption from './AvailableOption';
import { OptionTypes } from '../types';

import type { Service, OrderSummary } from '../types';

type Props = {
  summary?: OrderSummary,
  options: Array<Service>,
  addOption: (service: number, previous: boolean) => void,
  removeOption: (id: number) => void,
};

class AvailableOptions extends React.Component {
  static props: Props;

  constructor(props) {
    super(props);
    this.state = { isBasicSelectOverridden: false };
  }

  componentDidUpdate(prevProps: Props) {
    const { summary } = this.props;
    if (prevProps.summary?.hasCancellationOption !== summary?.hasCancellationOption) {
      this.setBasicOverridden(false);
    }
  }

  setBasicOverridden(value: Boolean) {
    this.setState({
      isBasicSelectOverridden: value,
    });
  }

  toggleSelectedOption = (service: number) => {
    const { addOption } = this.props;
    addOption(service);
    this.setBasicOverridden(false);
  };

  removeSelectedOption = () => {
    const { removeOption, summary } = this.props;
    let selectedId = '';
    if (summary.hasCancellationOption && !summary.isCancellationOptionFree) {
      selectedId = summary.cancellationOptionId;
    }
    removeOption(selectedId);
    if (!summary.isCancellationOptionFree) {
      this.setBasicOverridden(true);
    }
  };

  render() {
    const { summary, options, t } = this.props;
    const { isBasicSelectOverridden } = this.state;
    const insurances = options.filter((s) => summary.services.map((x) => x.id).includes(s.id));
    const cancelOption = options.find((s) => s.isInsurance);

    function isOptionChecked(id: number) {
      const hasRemoteInsurance = id ?
        insurances.map((s) => s.id).indexOf(id) > -1 :
        insurances.length === 0;
      return !!summary && hasRemoteInsurance;
    }

    const isCancellationChecked = !isBasicSelectOverridden && isOptionChecked(summary.cancellationOptionId) && !isOptionChecked('');
    const isBasicChecked = !isCancellationChecked;

    return (
      <div className="optionSelection">
        <div className="services">
          {cancelOption ? (
            <AvailableOption
              optionType={OptionTypes.premium}
              price={cancelOption.price === 0 ? t('included') : `${cancelOption?.price}€`}
              hasOption={isCancellationChecked}
              addOption={this.toggleSelectedOption}
              removeOption={this.removeSelectedOption}
              cancelServiceId={summary.cancellationOptionId}
              isRecommended
            />
          ) : ''}
          {!summary.isCancellationOptionFree && (
            <AvailableOption
              optionType={OptionTypes.basic}
              price={t('free')}
              hasOption={isBasicChecked}
              addOption={this.toggleSelectedOption}
              removeOption={this.removeSelectedOption}
              cancelServiceId=""
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(AvailableOptions);

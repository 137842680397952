import React from 'react';
import { withTranslation } from 'react-i18next';
import { ButtonIcon, Scrollable } from '@bluevalet/common-ui';
import HistoricIcon from '@bluevalet/react-icons/historic';
import UserIcon from '@bluevalet/react-icons/user';
import OutsideWashIcon from '@bluevalet/react-icons/outside-wash';
import ValetIcon from '@bluevalet/react-icons/valet';
import useMediaQuery from '../../../plugins/useMediaQuery';

function ScrollMenu({ t }) {
  const isMobile = useMediaQuery('(max-width: 960px)');

  const menu = [
    {
      id: 1,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <HistoricIcon size={24} />
          <span>{t('booking-menu_order')}</span>
        </ButtonIcon>
      ),
      anchor: 'order-detail',
      offsetTop: -500,
      offsetBottom: isMobile ? 171 : 65,
    },
    {
      id: 2,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <OutsideWashIcon size={24} />
          <span>{t('booking-menu_services')}</span>
        </ButtonIcon>
      ),
      anchor: 'order-services',
      offsetTop: isMobile ? -190 : -70,
      offsetBottom: isMobile ? 171 : 65,
    },
    {
      id: 3,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <ValetIcon size={24} />
          <span>{t('booking-menu_options')}</span>
        </ButtonIcon>
      ),
      anchor: 'order-options',
      offsetTop: isMobile ? -190 : -70,
      offsetBottom: isMobile ? 171 : 65,
    },
  ];

  const loginForm = document.getElementById('LoginForm');
  const validateForm = document.getElementById('ValidateForm');

  if (loginForm) {
    menu.push({
      id: 4,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <UserIcon size={24} />
          <span>{t('booking-menu_signin')}</span>
        </ButtonIcon>
      ),
      anchor: 'SignInUpForms',
      offsetTop: isMobile ? -190 : -70,
      offsetBottom: isMobile ? 171 : 65,
    });
  } else if (validateForm) {
    menu.push({
      id: 4,
      label: (
        <ButtonIcon light data-cy="button-icon-2">
          <UserIcon size={24} />
          <span>{t('booking-menu_informations')}</span>
        </ButtonIcon>
      ),
      anchor: 'order-informations',
      offsetTop: isMobile ? -190 : -70,
      offsetBottom: isMobile ? 171 : 65,
    });
  }

  return (
    <div className="ScrollMenu">
      <Scrollable
        items={menu}
        defaultActiveLink="order-detail"
        scrollAfter={() => { }}
        validatePreviousAnchors={() => { }}
      />
    </div>
  );
}

export default withTranslation()(ScrollMenu);

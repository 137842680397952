/* @flow */

import React from 'react';

type Props = {
  error?: string,
};

const BookingError = ({ error }: Props) => {
  if (error) {
    return <div className="message alert">{error}</div>;
  }

  return null;
};

export default BookingError;

/* @flow */

import Big from 'big.js';
import moment from 'moment';
import isEmpty from 'lodash.isempty';

import * as Immutable from 'immutable';
import type Moment from 'moment';
import type { FormContent } from './components/Form';
import type { Site, Slots } from './types';
import { promotionDisplayRules } from '../../conf/domain';

type Slot = {
  value: string,
  isUnavailable: boolean,
};

Big.RM = 1; // HALF_UP

export const isErrorField = (forceValidation?: boolean, value?: any) => {
  const isNotEmpty = !!value && value.toString().length > 0;
  return forceValidation && !isNotEmpty;
};

export const displayDate = (date?: Date) => (date ? moment(date).format('DD/MM/YYYY') : '');

export const displayDecimal = (
  number: number,
  withoutDecimal: boolean = false,
) => {
  const value = new Big(number).toFixed(2).toString();
  if (withoutDecimal) {
    return value.replace('.00', '');
  }

  return value;
};

export const getSiteWithId = (sites: Array<Site>, id?: string) => (
  sites.find((s) => s.id.toString() === id)
);

export const displaySite = (sites: Array<Site>, id?: string) => {
  const site = getSiteWithId(sites, id);
  return site ? site.name : '';
};

export const filterObjectFields = (object: Object, fieldNames: Array<string>) => {
  if (!object || !fieldNames) {
    return null;
  }
  const entries = fieldNames
    .map((fieldName) => [fieldName, object[fieldName]])
    .filter(([, value]) => value !== undefined);
  return Object.fromEntries(entries);
};

export const hasEmptyValue = (object: Object) => (
  Object.values(object).some(
    (value) => value === undefined || value === null || value.toString().length === 0,
  )
);

export const serializeBooking = (
  departure: FormContent,
  arrival: FormContent,
) => {
  const capitalizeFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const undefinedIfEmpty = (value?: any) => {
    if (typeof value === 'string' && value.length > 0) return value;
    if (value instanceof Date) return displayDate(value);
    if (value instanceof Object && !isEmpty(value)) return value;
    return undefined;
  };

  const prependToField = (target: Object, prefix: string) => (
    Object.keys(target).reduce((output, key) => {
      const newOutput = { ...output };
      const value = target[key];
      const newKey = prefix + capitalizeFirst(key);
      newOutput[newKey] = undefinedIfEmpty(value);
      return newOutput;
    }, {})
  );

  const start = prependToField(departure, 'start');
  const end = prependToField(arrival, 'end');

  return {
    start,
    end,
  };
};

export const mapStateFromForm = ($form: any) => {
  const findValueWithKey = (key: string) => {
    const value = $form.find(`input[name="${key}"]`).val();
    if (value !== undefined && value.length > 0) {
      return value;
    }
    return undefined;
  };

  const findJsonWithKey = (key: string) => {
    const value = $form.find(`input[name="${key}"]`).val();
    if (value !== undefined && value.length > 0) {
      return JSON.parse(value);
    }
    return {};
  };

  const toDate = (value?: string) => {
    if (value) {
      return moment(value, 'DD/MM/YYYY').toDate();
    }

    return undefined;
  };

  const departure = {
    site: findValueWithKey('startSite'),
    checkpoint: findValueWithKey('startCheckpoint'),
    date: toDate(findValueWithKey('startDate')),
    hour: findValueWithKey('startHour'),
    tripNumber: findValueWithKey('startTripNumber'),
    tripDetail: findJsonWithKey('startTripDetail'),
  };

  const arrival = {
    site: findValueWithKey('endSite'),
    checkpoint: findValueWithKey('endCheckpoint'),
    date: toDate(findValueWithKey('endDate')),
    hour: findValueWithKey('endHour'),
    tripNumber: findValueWithKey('endTripNumber'),
    tripDetail: findJsonWithKey('endTripDetail'),
  };

  const getOptions = () => Immutable.Set(
    (findValueWithKey('services') || '')
      .split(',')
      .map(Number)
      .filter((s) => s > 0),
  );

  const voucher = findValueWithKey('voucher');
  const business = findValueWithKey('business');
  const travelType = findValueWithKey('travelType');

  const getWorker = () => {
    const $input = $form.find('input[name="business"]');
    if ($input && $input.val() && $input[0].hasAttribute('data-id')) {
      return {
        id: $input.attr('data-id'),
        business: $input.attr('data-name'),
        name: $input.attr('data-worker'),
      };
    }

    return undefined;
  };

  return {
    booking: {
      departure,
      arrival,
    },
    cart: {
      options: getOptions(),
      voucher,
      travelType,
      business,
      worker: getWorker(),
    },
  };
};

// TODO: Do we need to select a slot using preferred one
export const getAvailableTimesFromSlots = (
  slots: Slots,
  start?: Moment,
  end?: Moment,
): Array<Slot> => {
  if (end.isBefore(start)) {
    return [];
  }

  const rangeStart = moment(slots.startTime, 'HH:mm');
  const rangeEnd = moment(slots.endTime, 'HH:mm');

  const range = moment.range(rangeStart, rangeEnd);

  const slotsRange = Array.from(range.by('minutes', { step: slots.step }))
    .map((slot, index) => {
      const value = slot.format('HH:mm');
      return ({
        slot,
        value,
        isUnavailable: slots.unavailables.indexOf(index) !== -1,
      });
    });

  if (start.isValid()) {
    return slotsRange.filter(({ slot }) => slot.isSame(start)
      || slot.isSame(end)
      || (slot.isAfter(start) && slot.isBefore(end)
      ));
  }

  return slotsRange;
};

export const isDateBefore = (currentDate: Date, minDate: Date) => (
  moment(minDate).isAfter(moment(currentDate))
);

export const hasDateChanged = (from?: Date, to?: Date) => {
  if (from !== undefined && to !== undefined) {
    return from.getTime() !== to.getTime();
  }

  return from !== to;
};

export const getAvailableSlot = (tripDetail, slots) => {
  const arrivalTime = moment(tripDetail.arrivalTime, 'DD/MM/YYYY HH:mm');
  const minArrivalTime = arrivalTime.clone().subtract(2, 'm');
  const maxArrivalTime = arrivalTime.clone().add(2, 'm');

  return slots.find((t) => {
    const momentSlot = moment(t.value, 'HH:mm');
    const slotValue = arrivalTime.clone().hour(momentSlot.hour()).minute(momentSlot.minute());
    const isBetween = slotValue.isBetween(minArrivalTime, maxArrivalTime, 'minutes', '[]');
    return isBetween;
  });
};

export const isDisplayablePromotion = (
  totalWithoutPromotion,
  total,
  displayRules = promotionDisplayRules,
) => {
  if (totalWithoutPromotion) {
    const isValidAmount = totalWithoutPromotion - total >= displayRules.minPromotionAmount;
    const isValidPercentage =
      Number(((totalWithoutPromotion - total) / totalWithoutPromotion).toFixed(2)) >=
      displayRules.minPromotionCoeff;

    return isValidAmount && isValidPercentage;
  }
  return false;
};

export const calcDiscount = (totalWithoutPromotion, total) => {
  if (totalWithoutPromotion) {
    return Number((((totalWithoutPromotion - total) / totalWithoutPromotion) * 100).toFixed(0));
  }
  return 0;
};

export const stringNotEmpty = (s) => s && s !== '';

/* @flow */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { getSiteWithId, displayDecimal } from '../utils';
import type { FormContent } from './Form';
import type { Site } from '../types';

type Props = {
  t: (key: string) => string,
  sites: Array<Site>,
  departure: FormContent,
  arrival: FormContent,
};

const getLowerMaxHeight = (
  sites: Array<Site>,
  departure?: string,
  arrival?: string,
) => {
  const departureSite = getSiteWithId(sites, departure);
  const arrivalSite = getSiteWithId(sites, arrival);
  const values = [departureSite?.maxHeight, arrivalSite?.maxHeight].filter((x) => !!x);
  return values.length > 0 ? Math.min(...values) : null;
};

const SiteInfo = ({ t, sites, departure, arrival }: Props) => {
  if (departure.site || arrival.site) {
    const maxHeight = getLowerMaxHeight(sites, departure.site, arrival.site);
    if (maxHeight) {
      const displayMaxHeight = displayDecimal(maxHeight);
      return (
        <div className="site-info">
          <svg>
            <use xlinkHref="#icon__info" />
          </svg>
          {`${t('booking-park_max_height')}: ${displayMaxHeight} m. ${t(
            'booking-park_max_height_info',
          )}`}
        </div>
      );
    }
  }

  return null;
};

export default withTranslation()(SiteInfo);

import $ from 'jquery';
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils';
import { getLangFromCookies } from '../utils/cookies';

/**
 * Return default country from I18n cookie
 *
 * @returns {string}
 */
const getDefaultCountry = () => {
  switch (getLangFromCookies()) {
    case 'fr':
      return 'FR';
    case 'es':
      return 'ES';
    case 'nl':
      return 'BE';
    case 'pt':
      return 'PT';
    default:
      return 'auto';
  }
};

/**
 * Phone number validation
 *
 * @param iti -> intl-tel-input instance
 * @param input -> input Jquery instance
 * @param hiddenInput -> hidden input Jquery instance
 * @param hiddenValid
 * @param hasInitialValue
 *
 * @returns {null|*}
 */
const validate = (iti, input, hiddenInput, hiddenValid, hasInitialValue) => {
  const isValid = iti.isValidNumber();

  if (!hasInitialValue || isValid) {
    hiddenInput.val(iti.getNumber());
  } else {
    hiddenInput.val(input.val());
  }

  if (isValid) {
    input.val(iti.getNumber(2));
  }

  hiddenValid.val(isValid || hasInitialValue ? 'true' : 'false');

  return isValid;
};

export const initPhoneNumberInput = (input) => {
  if (input) {
    const inputTel = $(input);
    const iti = intlTelInput(inputTel[0], {
      initialCountry: getDefaultCountry(),
      preferredCountries: ['FR', 'ES', 'BE', 'PT', 'GB', 'DE'],
      utilsScript,
      separateDialCode: true,
      autoPlaceholder: 'aggressive',
    });

    const getHasInitialValue = () => {
      const initialValueAttr = inputTel.attr('data-initial-value');
      if (initialValueAttr != null && initialValueAttr.length > 0) {
        return initialValueAttr === 'true';
      }
      return inputTel.val().length > 0;
    };

    const name = inputTel.attr('name');

    inputTel.removeAttr('name');

    const hiddenInput = inputTel
      .clone()
      .attr('name', name)
      .attr('type', 'hidden')
      .removeAttr('id')
      .removeAttr('class')
      .insertAfter(inputTel);

    const hiddenValid = $(
      `<input type="hidden" name="${name}-valid"/>`,
    ).insertAfter(hiddenInput);
    const hasInitialValue = getHasInitialValue();

    validate(iti, inputTel, hiddenInput, hiddenValid, hasInitialValue);

    inputTel.on('blur', () => validate(iti, inputTel, hiddenInput, hiddenValid, false));
  }
};

export default () => {
  $('.intl-phoneNumber').each((_, elem) => {
    initPhoneNumberInput(elem);
  });
};

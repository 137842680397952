/* @flow */

import * as Immutable from 'immutable';
import {
  TOGGLE_OPTION,
  REMOVE_OPTION,
  CLEAR_OPTIONS,
  UPDATE_VOUCHER,
  UPDATE_BUSINESS,
  CART_PERSISTED,
  CART_PERSIST_ERROR,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE,
  UPDATE_TRAVEL_TYPE,
} from '../actions/cart';
import type { State } from './index';
import type { OrderSummary, BusinessWorker } from '../types';

export type CartState = {
  options?: Immutable.Set<number>,
  summary?: OrderSummary,
  voucher?: string,
  travelType?: string,
  business?: string,
  worker?: BusinessWorker,
  error?: string,
  isCartReady?: boolean,
  isUpdatingVoucher?: boolean
};

const initialState = {};

export default (state: CartState = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_OPTION: {
      const toggle = (from) => (from.has(action.option)
        ? from.delete(action.option)
        : from.add(action.option)
      );

      const initial = () => {
        const fromSummary = state.summary
          ? state.summary.services
            .map((s) => s.id)
            .filter((id) => {
              if (action.previous) {
                return id !== action.option;
              }

              return true;
            })
          : [];

        const result = Immutable.Set(fromSummary);

        if (action.previous) {
          return result;
        }

        return result.add(action.option);
      };

      const nextOptions = state.options ? toggle(state.options) : initial();

      return {
        ...state,
        options: nextOptions,
      };
    }
    case REMOVE_OPTION: {
      const { options } = state;
      if (options) {
        return {
          ...state,
          options: options.delete(action.option),
        };
      }

      return state;
    }
    case CLEAR_OPTIONS:
      return {
        ...state,
        options: undefined,
      };
    case UPDATE_VOUCHER:
      return {
        ...state,
        isUpdatingVoucher: true,
        voucher: action.voucher,
      };
    case UPDATE_BUSINESS:
      return {
        ...state,
        business: action.business,
      };
    case UPDATE_TRAVEL_TYPE:
      return {
        ...state,
        travelType: action.travelType,
        business: action.business,
      };
    case FETCH_SUMMARY_SUCCESS:
    case CART_PERSISTED:
      return {
        ...state,
        summary: action.summary,
        isUpdatingVoucher: false,
        error: undefined,
      };
    case CART_PERSIST_ERROR:
    case FETCH_SUMMARY_FAILURE:
      return {
        ...state,
        summary: undefined,
        isUpdatingVoucher: false,
        error: action.error.displayMessage,
      };
    default:
      return state;
  }
};

export const getOptions = (state: State) => state.cart.options;
export const getSummary = (state: State) => state.cart.summary;
export const getVoucher = (state: State) => state.cart.voucher;
export const getIsUpdatingVoucher = (state: State) => state.cart.isUpdatingVoucher;
export const getBusiness = (state: State) => state.cart.business;
export const getTravelType = (state: State) => state.cart.travelType;
export const getWorker = (state: State) => state.cart.worker;
export const getError = (state: State) => state.cart.error;
